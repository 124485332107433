<template>
  <div class="order-confirm-page">
    <div class="container">
      <div class="goods-base-info">
        <div class="goods-img">
          <img :src="productData.productImg || defaultProductImg" alt="" />
        </div>
        <div class="base-info">
          <div class="info-name one-line-hidden">
            {{ productData.productName }}
          </div>
          <div class="property-info">
            {{ `${productData.specName}; ${productData.attrName}` }}
          </div>
          <div class="money-num">
            <span class="money"
              ><i class="unit">¥</i
              >{{
                productInfo.productPrice
                  ? mathManage.formatMoney(productInfo.productPrice, 2)
                  : ""
              }}</span
            >
            <span
              class="old-money"
              v-if="productInfo.productFace || productInfo.productFace == 0"
              >原价：¥{{
                productInfo.productFace
                  ? mathManage.formatMoney(productInfo.productFace, 2)
                  : ""
              }}</span
            >
          </div>
        </div>
      </div>

      <div class="buy-info">
        <van-cell-group inset>
          <van-cell is-link @click="clickTicket">
            <template #title>
              <span style="margin-left: 0.2rem">优惠券</span>
              <span class="coupon-tab" v-if="selectCouponData"
                >已享卡券优惠价</span
              >
            </template>
            <template #right-icon>
              <div class="coupon-cell">
                <span class="amount" v-if="selectCouponData"
                  >-￥{{ selectCouponData.amount }}</span
                >
                <span class="tip" v-else>
                  可用优惠券{{ couponList.length || 0 }}个</span
                >
                <van-icon class="tip" v-if="!selectCouponData" name="arrow" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>

      <div class="template-info">
        <div class="account-box" v-if="productInfo.deliveryMode == 10">
          <h6>
            {{
              filterTemplateData ? filterTemplateData.accountName : "充值账号"
            }}
          </h6>
          <div class="account-input">
            <van-popover
              v-model="showPopover"
              trigger="click"
              placement="bottom"
              class="account-popover"
            >
              <template #reference>
                <van-field
                  class="account-field"
                  v-model="account"
                  clearable
                  clear-trigger="always"
                  :placeholder="`请输入${
                    filterTemplateData
                      ? filterTemplateData.accountName
                      : '充值账号'
                  }`"
                  @blur="inputBlur"
                />
              </template>
              <div class="account-history">
                <div class="list-box">
                  <div
                    class="list-item"
                    v-for="item in accountHistoryList"
                    :key="item.recharge"
                  >
                    <span
                      class="text one-line-hidden"
                      @click="selectAccount(item)"
                      >{{ item.recharge }}</span
                    >
                    <span class="icon"
                      ><van-icon name="cross" @click="delAccount(item)"
                    /></span>
                  </div>
                </div>
              </div>
            </van-popover>
          </div>
        </div>
        <van-cell-group inset class="buy-num-box">
          <van-cell
            required
            v-if="!gameData.isServiceArea"
            title="购买数量"
            value=""
          >
            <template #right-icon>
              <van-stepper
                v-model="buyNum"
                integer
                :min="1"
                :max="300000"
                input-width="1rem"
              />
            </template>
          </van-cell>
        </van-cell-group>
        <game-template
          ref="templateRef"
          :data="gameData"
          :filterTemplateData="filterTemplateData"
          v-if="gameData.isServiceArea"
          @changeBuyNum="changeBuyNum"
        />
      </div>

      <div class="buy-tip-box" v-if="productInfo.deliveryMode == 20">
        <van-icon class="tip-icon" name="info-o" />
        <span class="tip-text"
          >卡密商品在付款后，可在订单详情中查看卡密，按照商品详情中的步骤完成充值</span
        >
      </div>
    </div>
    <div class="pay-footer">
      <div class="pay-info">
        <div class="sum-money">
          <span class="title">总计：</span>
          <div class="money-box">
            <span class="tip">￥</span>
            <span class="price">{{
              productInfo.orderRealMoney == 0
                ? "0.00"
                : mathManage.formatMoney(productInfo.orderRealMoney, 2)
            }}</span>
          </div>
        </div>
        <div class="sum-count">
          商品共计{{ buyNum }}件
          <span class="couponPrice" v-if="productInfo.couponPrice"
            >已优惠￥{{ productInfo.couponPrice }}</span
          >
        </div>
      </div>
      <div class="pay-btn" @click="toBuy">立即购买</div>
    </div>
    <van-action-sheet
      class="select-ticket-box"
      v-model="showTicket"
      title="选择卡券"
      :close-on-click-overlay="false"
      @cancel="confimSelect"
      close-icon="clear"
    >
      <div class="ticket-list">
        <div class="table-list" v-if="couponList && couponList.length > 0">
          <!-- <h3 class="check-coupon-tips">
            您已选中1张优惠券，共可抵用 <span>{{}}</span>
          </h3> -->
          <div
            class="list-item"
            v-for="item in couponList"
            :key="item.cardNumber"
            @click="selectCoupon(item)"
          >
            <!-- <div class="item-info">
              <div class="item-name">
                <span class="coupon-name one-line-hidden">{{
                  item.couponName
                }}</span>
                <span class="product-name">立减{{ item.amount }}元</span>
              </div>
              <div class="item-time">
                有效期：{{ item.startDate }} - {{ item.endDate }}
              </div>
            </div> -->

            <div class="ticket-price-box">
              <!-- <div class="tag">商品抵扣券</div> -->
              <svg-icon iconClass="vouchercoupon" class="voucher-coupon" />
              <div class="ticket-price one-line-hidden">
                <div class="unit-price">
                  <span class="unit">￥</span>
                  <span>{{ mathManage.formatMoney(item.amount, 2) }}</span>
                </div>
                <div class="title-info">指定商品可用</div>
              </div>
            </div>
            <div class="ticket-info-box">
              <div class="name">{{ item.couponName }}</div>
              <div class="time">
                <span>截止至: {{ item.endDate }}</span>
              </div>
              <div class="info">
                <svg-icon iconClass="shop_page" class="shop-page" />
                <span class="info-text">
                  {{ item.specName }}；{{ item.attrName }}</span
                >
              </div>
              <span class="circle circle-top"></span
              ><span class="circle circle-bottom"></span>
              <div class="check-icon">
                <div v-show="item.checked" class="icon-checked">
                  <svg-icon iconClass="yes" class="yes icon" />
                </div>
                <div v-show="!item.checked" class="icon-nocheck"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="action-box" v-if="couponList && couponList.length > 0">
          <div class="action-btn" @click="confimSelect">完成</div>
        </div>
        <div v-if="!couponList || couponList.length == 0" class="no-data">
          <img :src="require('../assets/images/no-card.png')" alt="" />
          <span class="no-text">抱歉，当前暂无卡券</span>
        </div>
      </div>
    </van-action-sheet>
    <van-popup v-model="showConfirm" round>
      <div class="confirm-buy">
        <h3>请确认充值账号</h3>
        <div class="confirm-account">{{ account }}</div>
        <div class="confirm-btn">
          <span class="cancel" @click="showConfirm = false">取消</span
          ><span class="ok" @click="confirmOk">确认充值</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  ActionSheet,
  Icon,
  Checkbox,
  Field,
  Stepper,
  Popover,
  Popup,
} from "vant";
// import { productType } from "@/utils/dictionary";
import mathManage from "@/utils/mathManage";
// import gameData from "@/utils/gameData";
import {
  GetAvailableCoupons,
  unifysendorder,
  confirmOrder,
  getTemplate,
  GetRechargeAccountHistory,
} from "@/api/common";
import { mapMutations, mapState } from "vuex";
import GameTemplate from "@/components/GameTemplate.vue";
import "../assets/images/svg/vouchercoupon.svg";
import "../assets/images/svg/shop_page.svg";
import "../assets/images/svg/yes.svg";

export default {
  name: "OrderConfirm",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [Stepper.name]: Stepper,
    GameTemplate,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
  },
  data() {
    return {
      mathManage,
      showTicket: false,
      checkedIntegral: false,
      selectCouponData: null,
      couponList: [],
      productInfo: {},
      productData: {},
      templateInfo: null,
      buyNum: 1,
      account: "",
      submitDisabled: false,
      gameData: {},
      accountHistoryList: [],
      showPopover: false,
      showConfirm: false,
    };
  },
  computed: {
    ...mapState(["cardCount", "defaultProductImg"]),
    filterTemplateData: {
      get() {
        if (!this.gameData || !this.gameData.isServiceArea) {
          return null;
        }
        const obj = (window.configs?.filterTemplateData || []).find(
          (item) => item.templateId == this.gameData.addressId
        );
        return obj;
      },
      set() {},
    },
  },
  watch: {
    showPopover(newVal, oldVal) {
      if (
        newVal &&
        this.accountHistoryList.length > 0 &&
        this.productInfo.deliveryMode == 10
      ) {
        this.showPopover = true;
      } else {
        this.showPopover = false;
      }
    },
    buyNum(newVal, oldVal) {
      if (newVal != oldVal) {
        this.confirmOrder(this.selectCouponData?.cardNumber);
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    this.couponId = this.$route.query.couponId;
    const productData = sessionStorage.getItem("productData");
    if (!id || !productData) {
      this.$router.go(-1);
      return;
    }
    this.id = id;
    this.productData = JSON.parse(productData);
    this.$nextTick(() => {
      this.getCouponList(id);
      if (this.productData.deliveryMode == 10) {
        this.getTemplate(id);
      }
    });
    this.getHistoryAccount(id);
  },
  methods: {
    ...mapMutations(["setCardCount"]),
    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },
    // 获取充值模板信息
    async getTemplate(id) {
      const res = await getTemplate({
        productId: id,
      });
      if (res && res.code == 0) {
        this.gameData = res.data || {};
      } else {
        // this.$toast(res.message);
      }
    },

    // 获取历史账号
    async getHistoryAccount(id) {
      const res = await GetRechargeAccountHistory({
        productId: id,
      });
      if (res && res.code == 0) {
        this.accountHistoryList = res.data?.list || [];
        this.account = (res.data?.list || [])[0]?.recharge || "";
      }
    },

    // 选择历史账号
    selectAccount(data) {
      this.account = data.recharge;
      this.showPopover = false;
    },

    // 删除历史账号
    delAccount(data) {
      this.accountHistoryList = this.accountHistoryList.filter(
        (item) => item.recharge != data.recharge
      );
      this.$nextTick(() => {
        if (this.accountHistoryList.length == 0) {
          this.showPopover = false;
        }
      });
    },

    // 商品数量改变
    changeBuyNum(buyNum) {
      this.buyNum = buyNum;
    },

    // 确认订单
    async confirmOrder(cardNumber) {
      this.$loading("请稍等...");
      try {
        const res = await confirmOrder({
          tenantProductId: this.id,
          buyNum: this.buyNum,
          chargeAccount: this.account,
          cardNumber,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.productInfo = res.data;
          document.title =
            res.data.deliveryMode == 10 ? "充值信息" : "确认订单";
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
    // 获取卡券列表
    async getCouponList() {
      try {
        const res = await GetAvailableCoupons({
          ProductId: this.id,
        });
        if (res && res.code == 0) {
          const list = res.data?.list || [];
          const obj = list.find((item) => item.couponId == this.couponId);
          if (obj) {
            this.couponList = list.map((item) => {
              return {
                ...item,
                checked: item.couponId == this.couponId,
              };
            });
          } else {
            this.couponList = list.map((item, i) => {
              return {
                ...item,
                checked: i == 0,
              };
            });
          }
          this.$nextTick(() => {
            // 111
            this.selectCouponData = this.couponList.find(
              (item) => item.checked
            );
            console.log(this.selectCouponData);
          });
        } else {
          this.$message.error(res.message);
        }

        this.$nextTick(() => {
          this.confirmOrder(this.selectCouponData?.cardNumber || null);
        });
      } catch (error) {
        this.confirmOrder(null);
      }
    },
    // 点击卡券
    selectCoupon(data) {
      console.log(data);
      this.couponList = this.couponList.map((item) => {
        if (item.cardNumber == data.cardNumber) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return {
          ...item,
          checked: false,
        };
      });
    },
    // 选择卡券
    clickTicket() {
      this.showTicket = true;
    },
    // 确定选择
    confimSelect() {
      this.selectCouponData = this.couponList.find((item) => item.checked);
      this.showTicket = false;
      this.confirmOrder(
        this.selectCouponData ? this.selectCouponData.cardNumber : null
      );
    },
    // 选择积分
    changeIntegral() {
      this.checkedIntegral = !this.checkedIntegral;
    },
    // 取消选择开启弹框
    cancelTicket() {
      this.confimSelect();
      this.showTicket = false;
    },

    // 弹框确认充值
    confirmOk() {
      this.toPay();
    },

    // 立即购买
    toBuy() {
      if (this.productData.deliveryMode == 10) {
        if (!this.account) {
          this.$toast("请输入充值账号");
          return;
        }
        if (this.gameData.isServiceArea) {
          this.$refs.templateRef.toBuy((data) => {
            console.log(data);
            if (data) {
              this.templateInfo = data;
              this.showConfirm = true;
            }
          });
        } else {
          // 直充确认
          this.showConfirm = true;
        }
      } else {
        this.toPay();
      }
    },

    // 去支付
    async toPay() {
      if (this.submitDisabled) return;
      this.$loading("请稍等...");
      this.submitDisabled = true;

      try {
        const res = await unifysendorder({
          productId: this.id,
          chargeAccount: this.account,
          buyNum: this.buyNum,
          templateInfo: this.templateInfo,
          cardNumber: this.selectCouponData?.cardNumber || null,
          useIntetral: false,
        });
        // this.$toast.clear();
        this.submitDisabled = false;
        if (res && res.code == 0) {
          if (
            this.selectCouponData &&
            this.selectCouponData.cardNumber &&
            this.cardCount > 0
          ) {
            this.setCardCount(this.cardCount - 1);
          }
          if (res.data.isNeedToPay) {
            this.$router.push(`/mall/cashierDesk?id=${res.data.orderId}`);
          } else {
            this.$router.push(`/mall/orderDetails/${res.data.orderId}`);
          }
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.submitDisabled = false;
      }
    },
  },
  destroyed() {
    this.$toast.clear();
  },
};
</script>

<style lang="less" scoped>
.order-confirm-page {
  height: 100%;
  overflow-y: auto;

  .van-cell__title {
    color: #565656;
  }

  .container {
    margin-bottom: 1.4rem;
    padding: 0.2rem 0.16rem;
    box-sizing: border-box;
  }

  .van-cell::after {
    border: 0;
  }

  .goods-base-info {
    width: 100%;
    background-color: #fff;
    padding: 0.32rem 0.4rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 0.16rem;

    .goods-img {
      width: 2.04rem;
      height: 1.26rem;
      margin-right: 0.2rem;
      // background-color: chartreuse;
      border-radius: 0.08rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .base-info {
      flex: 1;
      overflow: hidden;

      .info-name {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .property-info {
        margin-top: 0.14rem;
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        display: flex;
        flex-wrap: wrap;
      }

      .money-num {
        font-size: 0.3rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ff503f;
        margin-top: 0.2rem;
        display: flex;
        align-items: center;

        .money {
          .unit {
            font-style: normal;
            font-size: 0.26rem;
            font-weight: 400;
          }
        }

        .old-money {
          font-size: 0.22rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-decoration: line-through;
          margin-left: 0.16rem;
        }
      }
    }
  }

  .template-info {
    background: #ffffff;
    border-radius: 0.16rem;
    margin-top: 0.2rem;
    overflow: hidden;
    padding: 0.32rem 0;

    .account-box {
      margin-bottom: 0.2rem;

      h6 {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #565656;
        padding: 0 0.2rem;
      }
    }

    .account-input {
      position: relative;

      /deep/ .van-cell {
        padding: 0.2rem;
      }

      .account-field {
        border-bottom: 0.02rem solid #f5f5f5;
        /deep/ .van-field__control {
          font-size: 0.56rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        /deep/ .van-field__clear {
          font-size: 0.32rem;
        }
      }

      .account-popover {
        width: 100%;
      }
    }
  }

  .buy-num-box::after {
    border: 0 !important;
  }

  .buy-info {
    margin-top: 0.2rem;
    background-color: #fff;
    border-radius: 0.16rem;
    overflow: hidden;

    .van-cell {
      padding-left: 0;
      border: none;
      border-width: 0 !important;
    }
    .coupon-tab {
      background: #ffecdd;
      border-radius: 0.04rem;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #d38f59;
      padding: 0 0.08rem;
      margin-left: 0.16rem;
    }
    .coupon-cell {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .amount {
        color: #ff4800;
      }

      .tip {
        color: #969799;
      }
    }
  }

  .select-ticket-box {
    .ticket-list {
      height: 66vh;
      // height: 50vh;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-bottom: 0.2rem;
      .now-info {
        font-size: 0.26rem;
        padding: 0 0.24rem;
        margin-bottom: 0.4rem;
        font-family: PingFangSC-Regular, PingFang SC;
      }
      .table-list {
        flex: 1;
        overflow-y: auto;
        padding: 0 0.24rem;
        .check-coupon-tips {
          font-size: 0.26rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 0.3rem;
        }
        .list-item {
          min-height: 2.16rem;
          overflow: hidden;
          display: flex;
          margin-bottom: 0.28rem;
          .ticket-price-box {
            flex: 0 0 2.16rem;
            position: relative;
            background-image: url(../assets/images/coupon-bgc.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 0 0 0 0.2rem;
            .voucher-coupon {
              position: absolute;
              width: 1.2rem;
              height: 0.6rem;
              top: -0.14rem;
              left: -0.11rem;
            }
          }

          .ticket-price {
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: 0.48rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fdffff;
            padding-top: 0.1rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .unit-price {
              display: flex;
              align-items: center;
              font-size: 0.56rem;

              .unit {
                font-size: 0.28rem;
                transform: translateY(0.08rem);
              }
            }
            .title-info {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fdffff;
              letter-spacing: 0.02rem;
            }
          }
          .ticket-info-box {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0.2rem 0.16rem 0.12rem;
            box-sizing: border-box;
            // overflow: hidden;
            background-image: url(../assets/images/right-bjt.png);
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            .check-icon {
              width: 0.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0.2rem;
              top: 42%;
              // z-index: 5555;
              .icon-checked {
                width: 0.35rem;
                height: 0.35rem;
                background-color: #ff7c5f;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                  width: 75%;
                  height: 75%;
                  transform: translateX(-0.02rem);
                  color: #fff;
                }
              }

              .icon-nocheck {
                width: 0.35rem;
                height: 0.35rem;
                background: #f4f4f4;
                border: 0.02rem solid #d8d8d8;
                border-radius: 50%;
              }
            }
            .circle {
              position: absolute;
              left: -0.15rem;
              display: inline-block;
              width: 0.3rem;
              height: 0.3rem;
              background-color: #fff;
              border-radius: 50%;
              &.circle-top {
                top: -0.15rem;
              }
              &.circle-bottom {
                bottom: -0.15rem;
              }
            }
            > div {
              padding: 0 0.12rem;
            }
            .name {
              width: 4.3rem;
              font-size: 0.26rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.06);
              flex: 1;
            }

            .info {
              display: flex;
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a1a1a1;
              margin-top: 0.08rem;
              padding-left: 0;
              .shop-page {
                width: 0.35rem;
                height: 0.35rem;
                flex: 0 0 0.35rem;
              }
              .info-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .time {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a1a1a1;
              margin-top: 0.08rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 0.14rem;
              border-bottom: 0.02rem dashed #ececec;
              .use-btn {
                box-shadow: 0rem 0.02rem 0.06rem 0rem rgba(0, 0, 0, 0.06);
                border-radius: 0.24rem;
                text-align: center;
                color: #fff;
                display: inline-block;
                padding: 0.05rem 0.24rem;
              }
              .get {
                background: #ff5b42;
                color: #ffffff;
              }
              .to-use {
                background: #fffcfc;
                border: 1px solid #ff5b42;
                color: #ff5b42;
              }
            }
          }
        }
      }

      .action-box {
        padding: 0 0.48rem;
        margin-top: 0.2rem;

        .action-btn {
          height: 0.8rem;
          background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
          border-radius: 0.4rem;
          font-size: 0.36rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.04rem;
        }
      }

      .no-data {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 2.2rem;
          height: 2.16rem;
          border-radius: 50%;
          margin-top: 0.4rem;
        }

        .no-text {
          margin-top: 0.24rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #999;
        }
      }
    }
  }

  .buy-tip-box {
    display: flex;
    margin-top: 0.2rem;

    .tip-icon {
      font-size: 0.26rem;
      margin-right: 0.1rem;
      color: #9797a1;
      padding-top: 0.04rem;
    }

    .tip-text {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9797a1;
    }
  }

  .pay-footer {
    width: 100%;
    height: 1rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    position: fixed;
    bottom: 0;

    .pay-info {
      .sum-money {
        display: flex;
        align-items: center;
        height: 0.4rem;
        .title {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          padding-top: 0.1rem;
        }
        .money-box {
          display: flex;
          align-items: center;
        }
        .tip {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff503f;
          margin-top: 0.12rem;
        }
        .price {
          font-size: 0.44rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff503f;
        }
      }

      .sum-count {
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9b9b9b;
        margin-top: 0.1rem;
        display: flex;
        align-items: center;
        .couponPrice {
          color: #ff503f;
          margin-left: 0.12rem;
        }
      }
    }

    .pay-btn {
      width: 2.8rem;
      height: 0.8rem;
      background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
      border-radius: 0.4rem;
      font-size: 0.36rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.01rem;
    }
  }
}
.confirm-buy {
  width: 5.9rem;
  height: 2.88rem;
  background: #ffffff;
  overflow: hidden;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding: 0 0.32rem;
  box-sizing: border-box;

  h3 {
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: 0.56rem;
  }
  .confirm-account {
    margin-top: 0.02rem;
    text-align: center;
  }

  .confirm-btn {
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 2.4rem;
      height: 0.72rem;
      border-radius: 0.52rem;
      border: 0.02rem solid #cdcdcd;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      display: flex;
      align-items: center;
      justify-content: center;

      &.ok {
        border-color: #ff5b42;
        background: #ff5b42;
        color: #ffffff;
      }
    }
  }
}
</style>

<style lang="less">
.account-history {
  width: 95vw;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

  .list-box {
    background-color: #fff;
    padding: 0.2rem 0.2rem 0.1rem;

    .list-item {
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      overflow: hidden;

      .text {
        flex: 1;
      }

      .icon {
        width: 1rem;
        text-align: right;
      }
    }
  }
}
</style>
