import { render, staticRenderFns } from "./OrderConfirm.vue?vue&type=template&id=86c68002&scoped=true&"
import script from "./OrderConfirm.vue?vue&type=script&lang=js&"
export * from "./OrderConfirm.vue?vue&type=script&lang=js&"
import style0 from "./OrderConfirm.vue?vue&type=style&index=0&id=86c68002&lang=less&scoped=true&"
import style1 from "./OrderConfirm.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86c68002",
  null
  
)

export default component.exports